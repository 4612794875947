import { ChangeDetectorRef, Component } from "@angular/core";
import { LoaderService } from "./services/loader.service";
import { NavigationStart, Router } from "@angular/router";
import { DataService } from "./services/data.service";
import { takeUntil } from "rxjs/operators";
import { CommonService } from "./services/common.service";
import { ActionCableService, Channel } from "angular2-actioncable";
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "builder-scheduler";
  loader: boolean = false;
  isLargeContainer: boolean = false;
  showAdditionalInfo: boolean = false;
  webSocketChannel: Channel;
  constructor(
    public loaderService: LoaderService,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private appDataService: DataService,
    private commonService: CommonService,
    private actionCableService: ActionCableService
  ) {}

  ngOnInit() {
    const domainPattern = /@(builder\.ai|engineer\.ai|x\.builder\.ai)$/;
    if (this.appDataService.urlParameters.call_initiator == 'b360') {
      this.showAdditionalInfo = true;
    } else if (domainPattern.test(this.appDataService.urlParameters.email)) {
      this.showAdditionalInfo = true;
    } else {
      this.showAdditionalInfo = false;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url === "/availability" && this.showAdditionalInfo) {
          this.isLargeContainer = true;
        } else {
          this.isLargeContainer = false;
        }
      }
    });

    this.webSocketChannel = this.actionCableService.
           cable(this.appDataService.getWebSocketUrl()).
           channel('CalenderNotificationsChannel');
    this.inItDisableSlotWebsocket();
  }

  inItDisableSlotWebsocket() {
    this.webSocketChannel.received()
                              .subscribe((message) => {
                                if (message && message['body']) {
                                 this.commonService.setDisableSlotWebSocketSubject(
                                    {
                                      action: 'received',
                                      message: message['body']
                                    }
                                  );
                                }
                              });

   this.commonService.disableSlotWebSocketSub$.asObservable().subscribe((message: { action: string, message: any}) => {
    if (message.action == 'send') {
      this.webSocketChannel.perform('speak', { ...message.message });
    }
   });                          
  }



  ngDoCheck() {
    this.cdr.detectChanges();
  }
}
