import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Slot } from "../models/interface";
import { Angulartics2Segment } from "angulartics2/segment";
import { UAParser } from "ua-parser-js";
const parser = new UAParser();
const userBrowser = parser.getBrowser();
const userDevice = parser.getDevice();
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */

@Injectable({ providedIn: "root" })
export class CommonService {
  public locale = "en-US";
  callType: string = "demo";
  call_header: string;
  show_avatar: boolean = true;
  call_description: string;
  description_color: string = "#9F4DFF";
  bookingId: number;
  call_productologist_avatar_url: string = "assets/images/bcl-caller.jpg";
  monthTimeSlot: Array<{
    date: string;
    time: Slot[];
  }> = [];
  //updateManagerDetail = new EventEmitter<any>();
  userName: string;
  location: string;
  participants = [];
  description_text: string;
  meeting_time: string;
  officeHoursStartTime: string;
  officeHoursEndTime: string;
  availabilityObject;
  schedulerInfo;
  messagePreferenceObject;
  start_time_in_all_timezone: string;
  start_time_slot: string;
  specCallInitializationTime: string;
  formRequiredFieldConfig: {
    company: boolean;
    product_bx: boolean;
  };
  followUpSpecObject: {
    name: string;
    company: string;
    description: string;
    email: string;
    phone: string;
    participants: any[];
  };
  scheduledByMetaBehavior = new BehaviorSubject({});
  public disableSlotWebSocketSub$ = new Subject();
  constructor(public angulartics2Segment: Angulartics2Segment) {
  }

  getFormatedPhone(phone: string) {
    if (phone.charAt(0) === "-") {
      return phone.substring(1);
    } else if (
      (phone.charAt(0) !== "-" &&
        phone.charAt(0) === "+" &&
        phone.length >= 10) ||
      phone.length === 10
    ) {
      return phone.trim();
    } else if (phone.length >= 10 && phone.charAt(0) !== "+") {
      return "+" + phone.trim();
    } else {
      return "";
    }
    // return phone ? phone.indexOf("-") !== -1 ? `+${phone.trim()}` : phone : phone;
  }

  public humanize(str) {
    var frags = str[0].split("_");
    for (let i = 0; i < frags.length; i++) {
      let index = i;
      frags[index] =
        frags[index].charAt(0).toUpperCase() + frags[index].slice(1);
    }
    return frags.join(" ");
  }

  setScheduledByMetaBehavior(data) {
    this.scheduledByMetaBehavior.next(data);
  }

  setDisableSlotWebSocketSubject(data) {
    this.disableSlotWebSocketSub$.next(data);
  }

  trackSegmentEvent(eventName: string, userInfo: any, eventParams: any) {
    this.angulartics2Segment.eventTrack(eventName, {
      user_browser: userBrowser,
      user_device: userDevice,
      ...userInfo,
      ...eventParams,
    });
  }
}
