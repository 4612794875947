import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-under-maintenance",
  templateUrl: "./under-maintenance.component.html",
  styleUrls: ["./under-maintenance.component.scss"],
})
export class UnderMaintenanceComponent implements OnInit {
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.hide();
  }
}
