import { Component, OnInit, OnDestroy } from "@angular/core";
import { SchedulerService } from "src/app/services/scheduler.service";
import { LoaderService } from "src/app/services/loader.service";
import moment from 'moment';
import { TimeZoneService } from "src/app/services/timezone.service";
import { CommonService } from "src/app/services/common.service";
import { Scheduling, AvailabilitySlot } from "src/app/models/interface";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";

/**
 * The scheduler Successful booked component
 * Created by Anil Suryavanshi on 11/05/20.
 */

@Component({
  selector: "app-schedule-success",
  templateUrl: "./schedule-success.component.html",
  styleUrls: ["./schedule-success.component.scss"],
})
export class ScheduleSuccessComponent implements OnInit, OnDestroy {
  constructor(
    public schedulerService: SchedulerService,
    public loaderService: LoaderService,
    private timezoneService: TimeZoneService,
    public commonService: CommonService,
    public appDataService: DataService,
    public router: Router
  ) {}

  scheduling: Scheduling;
  availabilityObj: AvailabilitySlot;
  selectedDateShow: string;
  timeShow: Date;
  call_label: string;
  message: string = "";
  addClassCopy = false;

  ngOnInit(): void {
    if (!this.commonService.bookingId) {
      this.router.navigate(["availability"]);
    }

    this.scheduling = this.schedulerService.scheduling;

    this.availabilityObj = { ...this.commonService.availabilityObject };
    if (this.availabilityObj) {
      let date = new Date(this.availabilityObj.selectedDate);
      this.selectedDateShow = moment(date).format("dddd, MMMM DD, yyyy");
      let selectedDateShow = moment(this.availabilityObj.selectedDate).format(
        "dddd, MMMM DD, yyyy"
      );
      // this.commonService.call_header = 'Product Expert Call - 30 min';
      this.timeShow = this.timezoneService.addTimeDate(
        this.availabilityObj.selectedTime?.start_time,
        this.commonService.meeting_time
      );
    }
    this.call_label = this.commonService.call_header;

    this.commonService.call_header =
      "You are scheduled with " + this.commonService.userName;
    this.commonService.call_description =
      "A calendar invite has been sent to your email.";
    this.commonService.description_color = "#9B9FB1";

    this.postMessageToParentWindow();
  }

  private postMessageToParentWindow() {
    if (
      this.appDataService.urlParameters.sub_status == "followup" ||
      this.appDataService.urlParameters.sub_status == "rescheduled" ||
      this.appDataService.urlParameters.sub_status == "followup_rescheduled"
    ) {
      window.opener.postMessage("refresh", "*");
    }
  }

  /**
   * @method reInvite
   * @description Again inviting the participants
   *
   */
  reInvite() {
    this.message = "";
    this.loaderService.show();
    this.schedulerService.reInvite(this.commonService.bookingId).subscribe(
      (res) => {
        if (res.message == "success") {
          this.message = "Invitation sent successfully.";
        }
        this.loaderService.hide();
      },
      (error) => {
        this.loaderService.hide();
      }
    );
  }

  addClassForCopy() {
    this.addClassCopy = true;
    setTimeout(() => {
      this.addClassCopy = false;
    }, 1300);
  }

  ngOnDestroy() {
    this.commonService.callType = "spec";
    this.commonService.bookingId = 0;
    this.commonService.call_header = "Product Expert Call - 30 min";
    this.commonService.call_description =
      "Speak to a product expert who will help you spec your perfect app or project.";
    this.commonService.description_color = "##9F4DFF";
    this.commonService.monthTimeSlot = [];
    this.commonService.userName = "";
    this.commonService.location = "";
    // sessionStorage.removeItem('availabilitySlot');
    // sessionStorage.removeItem('contactInfo');
    this.commonService.availabilityObject = null;
    this.schedulerService.scheduling = null;
  }

  openMeetingLink(link) {
    window.open(link, "_blank");
  }
}
