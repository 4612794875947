import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */
@Injectable({
  providedIn: "root",
})
export class LoaderService {
  /**
   * Loader common service for handling the server or delay response
   */
  showPageLoader = new BehaviorSubject<boolean>(false);
  show() {
    this.showPageLoader.next(true);
  }
  hide() {
    this.showPageLoader.next(false);
  }
}
