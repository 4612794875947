
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, provideRoutes } from '@angular/router';

import { DataService } from "./data.service";
import { CommonService } from './common.service';
import { map, catchError } from 'rxjs/operators';

/**
 * Created by Anil Suryavanshi on 11/05/20.
 */

@Injectable({providedIn:'root'})
export class HttpRequests {
  constructor(public commonService: CommonService, public http: HttpClient, private router: Router, private dataService: DataService) {
  }

  public get(url: string, Headers?, requestOptions?): Observable<any> {
    if (requestOptions) {
      return this.http.get(url, requestOptions).pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
        
    } else if (Headers) {
      return this.http.get(url,  Headers )
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    } else {
      return this.http.get(url)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    }
  }


  public post(url: string, data: any, Headers): Observable<any> {
    if (Headers) {
      return this.http.post(url, data,  Headers )
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    } else {
      return this.http.post(url, data)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    }
  }

  public put(url: string, data: any, Header): Observable<any> {
    if (Header) {
      return this.http.put(url, data,  Header)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    } else {
      return this.http.put(url, data)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    }
  }

  public delete(url: string, Header): Observable<any> {
    if (Header) {
      return this.http.delete(url, Header)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    } else {
      return this.http.delete(url)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    }
  }

  public patch(url: string, data: any, Header): Observable<any> {
    if (Header) {
      return this.http.patch(url, data, Header)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    } else {
      return this.http.patch(url, data)
      .pipe(map(res => this.parseResponse(res)),catchError((error: HttpErrorResponse) => {
        this.checkErrorStatus(error);
        return observableThrowError(error.error);
    }))
    }
  }

  public parseResponse(res: any): any {
    return res;
  }

  public handleError(error: any) {
    this.checkErrorStatus(error);
    return observableThrowError(error.error);
  }

  public checkErrorStatus(error) {
    if (error.status === 401) {
      this.router.navigate(['invalid-access']);
    } else if (error.status === 500) {
      this.router.navigate(['under-maintenance']);
    } else if (error.status === 404) {
     // if (this.dataService.getAuthToken()) {
        this.router.navigate(['not-found']);
      //}

    }
    return observableThrowError(error.error);
  }
}
