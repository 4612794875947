import { NgModule } from '@angular/core';
import { Routes, RouterModule, convertToParamMap, RouterLinkWithHref } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { ScheduleCalenderComponent } from './components/schedule-calender/schedule-calender.component';
import { SchedulerFormComponent } from './components/schedule-form/schedule-form.component';
import { ScheduleSuccessComponent } from './components/schedule-success/schedule-success.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { InvalidAccessComponent } from './invalid-access/invalid-access.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'loader',
    pathMatch: 'full'
  },
  {
    path: 'loader',
    component: LoaderComponent,
  },
  {
    path: 'availability',
    component: ScheduleCalenderComponent,
  },
  {
    path: 'schedule-form',
    component: SchedulerFormComponent,
  },
  {
    path: 'schedule-success',
    component: ScheduleSuccessComponent,
  },
  {
    path: 'invalid-access',
    component: InvalidAccessComponent,
  },
  {
    path: 'under-maintenance',
    component: UnderMaintenanceComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
