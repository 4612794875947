import { Component, OnInit, Input } from "@angular/core";
import { CommonService } from "src/app/services/common.service";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */
@Component({
  selector: "app-schedule-header",
  templateUrl: "./schedule-header.component.html",
  styleUrls: ["./schedule-header.component.scss"],
})
export class ScheduleHeaderComponent implements OnInit {
  call_type: string;
  description_color: string;
  seeSlots: boolean = false;

  public callHeader: String = "Product Expert Call - 30 min(s)";
  public callDescription: String =
    "Speak to a product expert who will help you spec your perfect app or project.";

  constructor(
    public commonService: CommonService,
    public router: Router,
    private appDataService: DataService
  ) {}
  ngOnInit(): void {
    this.seeSlots =
      this.appDataService.urlParameters.see_slots == "true" ? true : false;
  }

  ngOnDestroy() {}
}
