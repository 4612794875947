<header id="bcl-main-header">
  <!--Main Header-->
  <img class="bcl-main-logo" src="assets/images/new_logo_builder.svg" />
</header>
<main id="bcl-main-schedule">
  <!--Main content-->
  <div class="bcl-schedule-box" [ngClass]="{ lg: isLargeContainer }">
    <div class="bcl-schedule-inner-box">
      <app-schedule-header></app-schedule-header>
      <ng-container *ngIf="loaderService.showPageLoader | async">
        <div class="bcl-loader">
          <img src="assets/images/builder-loader.svg" alt="loader" />
        </div>
      </ng-container>
      <router-outlet></router-outlet>
    </div>
  </div>
</main>
