import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

/**
 * @description Filter pipe for searching the text
 */

 /**
 * Filter the list array 
 *
 * __Usage :__
 *   value | filter:model_search
 *
 * __Example :__
 *   {{ array |  filter : model}}
 *   filter to: matched
 */
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): unknown {

    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(it => {
      return it.toLowerCase().includes(searchText);
    });
  }

}
