import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { JsonConvert } from 'json2typescript';
/**
 * Created by Anil Suryavanshi on 12/05/20.
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public dataLoading = false;
  constructor(public router:Router, private appDataService: DataService) { }

  ngOnInit(): void {
    //sessionStorage.removeItem('availabilitySlot');
    //sessionStorage.removeItem('contactInfo');
    // remove the service info
    if (this.appDataService.urlParameters.token) {
        this.router.navigate(['/availability'])
    } else{
      this.router.navigate(['/invalid-access'])
    }

    
  }

}
