import { Injectable } from '@angular/core';
import { UrlParameterModel } from '../models/url-parameters-model/url-parameters-model';
import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
  /**
 * Created by Anil Suryavanshi on 12/05/20.
 */
@Injectable({
  providedIn: 'root'
})
export class ModelMapperService {
  public jsonConvert: JsonConvert;
  constructor() {
    this.jsonConvert = new JsonConvert();
    this.jsonConvert.operationMode = OperationMode.ENABLE; // print some debug data
    this.jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // allow null
   }

  mapToUrlParameterModel(mappedObj, jsonObject):UrlParameterModel{
    try {
      mappedObj = this.jsonConvert.deserializeObject(jsonObject, UrlParameterModel);
      return mappedObj;
    } catch (e) {
      console.log((<Error>e));
    }
  }
}
