import { JsonObject, JsonProperty } from "json2typescript";
/**
 * Created by Anil Suryavanshi on 11/05/20.
 */
@JsonObject
export class UrlParameterModel {
  @JsonProperty("app_name", String, true)
  app_name: string = null;
  @JsonProperty("call_type", String, true)
  call_type: string = null;
  @JsonProperty("token", String, true)
  token: string = null;
  @JsonProperty("project_idea", String, true)
  project_idea: string = null;
  @JsonProperty("generic_id", String, true)
  generic_id: string = null;
  @JsonProperty("name", String, true)
  name: string = null;
  @JsonProperty("email", String, true)
  email: string = null;
  @JsonProperty("phone", String, true)
  phone: string = null;
  @JsonProperty("company", String, true)
  company: string = null;
  @JsonProperty("store", String, true)
  store: Boolean = null;
  @JsonProperty("parent_builder_spec_call_id", String, true)
  parent_builder_spec_call_id: string = null;
  @JsonProperty("sub_status", String, true)
  sub_status: string = null;
  @JsonProperty("sub_status_origin", String, true)
  sub_status_origin: string = null;
  @JsonProperty("manager_email", String, true)
  manager_email: string = null;
  @JsonProperty("calender_booking_id", String, true)
  calender_booking_id: string = null;
  @JsonProperty("enterprise_tag", String, true)
  enterprise_tag: string = "false";
  @JsonProperty("scheduled_by_meta", String, true)
  scheduled_by_meta: any = "";
  @JsonProperty("see_slots", String, true)
  see_slots: any = "";
  @JsonProperty("from_app", String, true)
  from_app: any = "";
  @JsonProperty("channel_partner", String, true)
  channel_partner: any = "";
  @JsonProperty("screen_source", String, true)
  screen_source: any = "";
  @JsonProperty("studio_version", String, true)
  studio_version: any = "";
  @JsonProperty("source", String, true)
  source: any = "";
  @JsonProperty("call_initiator", String, true)
  call_initiator: any = "";
  @JsonProperty("manager_name", String, true)
  manager_name: string = null;
  @JsonProperty("build_card_type", String, true)
  build_card_type: any = "";
  @JsonProperty("country_code", String, true)
  country_code: any = "";
  @JsonProperty("cip", String, true)
  cip: string = "";
  @JsonProperty("vip", String, true)
  vip: string = "";
  @JsonProperty("subTypeFilter", String, true)
  subTypeFilter: string = "";
  @JsonProperty("subTypeFilters", String, true)
  subTypeFilters: string = "";
}
