import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SchedulerService } from "src/app/services/scheduler.service";
import { LoaderService } from "src/app/services/loader.service";
import { TimeZoneService } from "src/app/services/timezone.service";
import moment from 'moment';
import { CommonService } from "src/app/services/common.service";
import { DatePipe } from "@angular/common";
import {
  Scheduling,
  AvailabilitySlot,
  Participant,
} from "src/app/models/interface";
import { DataService } from "src/app/services/data.service";
import { ModalDirective } from "ngx-bootstrap/modal";
import { PhoneNumberUtil } from "google-libphonenumber";
import { ActionCableService, Channel } from "angular2-actioncable";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: "app-schedule-form",
  templateUrl: "./schedule-form.component.html",
  styleUrls: ["./schedule-form.component.scss"],
  providers: [DatePipe],
})
export class SchedulerFormComponent implements OnInit, AfterViewInit {
  message: string = "";
  scheduling: Scheduling = {
    name: "",
    email: "",
    countryCode: "",
    whatsapp_phone_number: "",
    sms_phone_number: "",
    comment: "",
    company: "",
    participants: [],
    attatchmets: [],
  };
  attatchmetsError = [];
  store: Boolean = false;
  showDescription: Boolean = true;
  public form: {
    participants: Participant[];
  };

  @ViewChild("countryCodeValue", { static: true }) countryCodeValue;
  @ViewChild("whatsappNumberCCodeValue", { static: false })
  whatsappNumberCCodeValue;
  @ViewChild("smsNumberCCodeValue", { static: false }) smsNumberCCodeValue;
  availabilityObj: AvailabilitySlot;
  cCodeValue = "";
  whatsappCCodeValue = "";
  smsCCodeValue = "";

  isReceiveNotification = false;
  isShowWhatsapp = false;
  isShowSMS = false;
  dateWith10MinDiff;
  currentDate: any = new Date();
  displayTimer: any;
  timerRunning = null;
  @ViewChild("sessionTimeoutModal", { static: false })
  sessionTimeoutModal: ModalDirective;
  @ViewChild("schedulerForm") protected schedulerForm;
  scheduleByMeta;
  internalEmailErr = false;
  readOnly = false;
  phoneError = "";
  phoneObj: any = {
    number: "",
    cc: "",
    region: "",
  };

  constructor(
    private router: Router,
    private _schedulerService: SchedulerService,
    public _loaderService: LoaderService,
    private timezoneService: TimeZoneService,
    public commonService: CommonService,
    private appDataService: DataService,
    private datePipe: DatePipe,
  ) {
    this.form = {
      participants: [],
    };
    this.getScheduleByMeta();
    // Add an initial participant form-entry.
    // this.addParticipant();
  }

  // I add a new participant record to the form-model.
  public addParticipant(): void {
    this.form.participants.push({
      id: Date.now(), // <--- uniqueness hook.
      name: "",
      email: "",
    });
  }

  public removeParticipant(id): void {
    let index = this.form.participants.findIndex((item) => {
      return id == item.id;
    });

    if (index > -1) {
      this.form.participants.splice(index, 1);
    }
  }

  ngOnInit(): void {
    if (
      this.appDataService.urlParameters.from_app &&
      this.appDataService.urlParameters.from_app == "marketing"
    ) {
      this.readOnly = true;
    }
    var minutesToAdd = 10;
    this.currentDate = moment(new Date().getTime() + 12 * 60000).format(
      "YYYY MM DD HH:mm:ss"
    );
    const datewith10Min = new Date(
      new Date(this.commonService.specCallInitializationTime).getTime() +
        minutesToAdd * 60000
    );
    this.dateWith10MinDiff = moment(datewith10Min).format(
      "YYYY MM DD HH:mm:ss"
    );
    const difference = moment(this.currentDate).diff(this.dateWith10MinDiff);
    if (difference >= 0) {
      this.startTimer(10);
    }

    if (!this.appDataService.urlParameters.token) {
      this.router.navigate(["invalid-access"]);
    }
    if (this.commonService.availabilityObject) {
      // sessionStorage.removeItem('availabilitySlot');
      this.availabilityObj = { ...this.commonService.availabilityObject };
      this.availabilityObj.selectedDate = new Date(
        this.availabilityObj.selectedDate
      );
      let selectedDateShow = moment(this.availabilityObj.selectedDate).format(
        "dddd, MMMM DD, yyyy"
      );
      // this.commonService.call_header = 'Product Expert Call - 30 min';

      // this.commonService.call_description =
      //   this.commonService.start_time_in_all_timezone;
      this.commonService.call_description = this.commonService.start_time_slot;

      //this.commonService.call_description = this.datePipe.transform(this.availabilityObj.selectedTime.start_time,'hh:mm a',this.availabilityObj.selectedTime.start_time.substr(this.availabilityObj.selectedTime.start_time.length-6)) + ' - '+ this.datePipe.transform(this.timezoneService.addTimeDate(this.availabilityObj.selectedTime.start_time, this.commonService.meeting_time),'hh:mm a',this.availabilityObj.selectedTime.start_time.substr(this.availabilityObj.selectedTime.start_time.length-6)) +', '+selectedDateShow;
      this.commonService.description_color = "#6C00EA";
      if (this.appDataService.urlParameters) {
        if (this.appDataService.urlParameters.store) {
          this.store = this.appDataService.urlParameters.store;
        }
        if (this.appDataService.urlParameters.name) {
          this.scheduling.name = this.appDataService.urlParameters.name;
        }
        if (this.appDataService.urlParameters.company) {
          this.scheduling.company = this.appDataService.urlParameters.company;
        }
        if (this.appDataService.urlParameters.email) {
          this.scheduling.email = this.appDataService.urlParameters.email;
        }
      }
    }
  }

  public ngAfterViewInit() {
    if (this.schedulerForm && this.schedulerForm.controls) {
      Object.keys(this.schedulerForm.controls).forEach((key) => {
        this.schedulerForm.controls[key].markAsTouched();
      });
    }

    if (
      this.commonService.availabilityObject &&
      this.appDataService.urlParameters
    ) {
      if (this.appDataService.urlParameters.phone) {
        this.scheduling.countryCode = this.appDataService.urlParameters.phone;
        this.cCodeValue = this.getContryCode(
          this.appDataService.urlParameters.phone
        );
      }

      if (this.commonService.callType !== "kickoff") {
        if (
          this.commonService.messagePreferenceObject &&
          (this.commonService.messagePreferenceObject.communication_channel ===
            "whatsapp" ||
            this.commonService.messagePreferenceObject.communication_channel ===
              "both")
        ) {
          this.isShowWhatsapp = true;
          this.isReceiveNotification = true;
          this.scheduling.whatsapp_phone_number =
            this.commonService.messagePreferenceObject.whatsapp_phone_number;
          this.whatsappCCodeValue = this.getContryCode(
            this.commonService.messagePreferenceObject.whatsapp_phone_number
          );
        } else {
          this.whatsappCCodeValue = this.cCodeValue;
        }

        if (
          this.commonService.messagePreferenceObject &&
          (this.commonService.messagePreferenceObject.communication_channel ===
            "sms" ||
            this.commonService.messagePreferenceObject.communication_channel ===
              "both")
        ) {
          this.isShowSMS = true;
          this.isReceiveNotification = true;
          this.scheduling.sms_phone_number =
            this.commonService.messagePreferenceObject.sms_phone_number;
          this.smsCCodeValue = this.getContryCode(
            this.commonService.messagePreferenceObject.sms_phone_number
          );
        } else {
          this.smsCCodeValue = this.cCodeValue;
        }
      }

      if (this.commonService.callType == "kickoff") {
        this.scheduling.comment =
          "Agenda : Kick off meeting- Team introductions and Revisiting Build Card";
      } else {
        // condition not required
        // this.showDescription = !this.store
        // set project idea from querystring
        if (this.appDataService.urlParameters.project_idea) {
          this.scheduling.comment =
            this.appDataService.urlParameters.project_idea;
        }

        // in case of followup patch specall object data
        if (
          this.appDataService.urlParameters.sub_status == "followup" &&
          this.commonService.followUpSpecObject
        ) {
          this.scheduling.name = this.commonService.followUpSpecObject.name;
          this.scheduling.email = this.commonService.followUpSpecObject.email;
          this.scheduling.company =
            this.commonService.followUpSpecObject.company;
          this.scheduling.comment =
            this.commonService.followUpSpecObject.description;
          this.scheduling.countryCode =
            this.commonService.followUpSpecObject.phone;
          let cCode;
          this.cCodeValue = "";
          let countries = this.countryCodeValue.countryService.getCountries();

          if (countries) {
            if (this.commonService.followUpSpecObject.phone.includes("+")) {
              let _cCode =
                this.commonService.followUpSpecObject.phone.split("+")[1];
              if (_cCode && _cCode.includes("-")) cCode = _cCode.split("-")[0];
            }
            for (let i = 0; i < countries.length; i++) {
              if (cCode === countries[i].dialCode) {
                this.cCodeValue = countries[i].countryCode;
              }
            }
          }
        }
      }
    }
  }

  /**
   * @method cancelCall
   * @description back to previous page
   */

  cancelCall() {
    this.router.navigate(["availability"]);
    this.clearBookingByWebSocketMessage();
  }

  /**
   * @method schedule
   * @description Schedule the time slots.
   */
  schedule() {
    const conditions = ["@builder.ai", "@engineer.ai", "@x.builder.ai"];
    if (conditions.some((el) => this.scheduling.email.endsWith(el))) {
      this.internalEmailErr = true;
      return;
    } else {
      this.internalEmailErr = false;
    }
    this.scheduling.participants = [];
    let participantsFormValid = true;
    if (this.form.participants.length > 0) {
      this.form.participants.forEach((item) => {
        if ((item.name && !item.email) || (!item.name && item.email)) {
          participantsFormValid = false;
        } else {
          if (item.name && item.email) {
            this.scheduling.participants.push({
              name: item.name,
              email: item.email,
            });
          }
        }
      });
    }

    if (!participantsFormValid) {
      this.message = "Please fill name & email for participants.";
      return;
    } else {
      this.message = "";
    }

    let data = {
      scheduling: this.scheduling,
    };
    this._schedulerService.scheduling = { ...this.scheduling };
    //sessionStorage.setItem('contactInfo', JSON.stringify(this.scheduling));
    // this.router.navigate(['schedule-success'], { replaceUrl: true })
    // console.log(data)
    // return;
    if (this.commonService.bookingId) {
      this._loaderService.show();
      let isStore = false;
      if (this.appDataService.urlParameters.store) {
        isStore = true;
      }

      const parent_builder_spec_call_id =
        this.appDataService.urlParameters.parent_builder_spec_call_id;
      const sub_status = this.appDataService.urlParameters.sub_status;
      const sub_status_origin =
        this.appDataService.urlParameters.sub_status_origin;

      this._schedulerService
        .scheduleAvailability(
          this.commonService.bookingId,
          this.availabilityObj.selectedDate,
          this.availabilityObj.selectedTime.start_time,
          this.scheduling,
          this.commonService.callType,
          isStore,
          parent_builder_spec_call_id,
          sub_status,
          sub_status_origin,
          this.appDataService.urlParameters.generic_id
        )
        .subscribe(
          (res) => {
            this._loaderService.hide();
            if (res.message == "success") {
              if (
                res.data.calenderbooking &&
                res.data.calenderbooking.user &&
                res.data.calenderbooking.user.first_name
              )
                this.commonService.userName =
                  res.data.calenderbooking.user.first_name +
                  " " +
                  res.data.calenderbooking.user.last_name;
              this.commonService.location = res.data.calenderbooking.location;
              this.commonService.participants =
                res.data.calenderbooking.participants;
              this.router.navigate(["schedule-success"], { replaceUrl: true });
              window.parent.postMessage(JSON.stringify(res.data), "*");
            } else {
              this.message = "Please go back and select another slot.";
            }
          },
          (error) => {
            this._loaderService.hide();
          }
        );
    } else {
      this.message = "Please go back and try again.";
    }
  }

  onFileChange(event) {
    this.attatchmetsError = [];
    if (this.scheduling.attatchmets.length + event.target.files.length > 10) {
      this.attatchmetsError.push({
        error: "Maximum 10 files can be uploaded in one go",
      });
      console.log(this.attatchmetsError);
      return;
    }
    for (let i = 0; i < event.target.files.length; i++) {
      let name = event.target.files[i].name;
      let type = event.target.files[i].type;
      let size = event.target.files[i].size;
      let modifiedDate = event.target.files[i].lastModifiedDate;
      if (Math.round(size / 1024) <= 5000) {
        this.scheduling.attatchmets.push(event.target.files[i]);
      } else {
        this.attatchmetsError.push({
          error: `${name} file is greater than 5 MB`,
        });
      }
      // console.log ('Name: ' + name + "\n" +
      //   'Type: ' + type + "\n" +
      //   'Last-Modified-Date: ' + modifiedDate + "\n" +
      //   'Size: ' + Math.round(size / 1024) + " KB");
    }
    // let files = this.scheduling.attatchmets;
    // let attatchmetsData = new FormData();
    // if (files.length > 0) {
    // files.forEach((file, index) => {
    // attatchmetsData.append('['+index+'][file]', file);
    // });
    // }
    // const data = {
    // calender_booking: {
    // attachments_attributes : attatchmetsData
    // }
    // }
    // console.log(data);
  }
  removeAttatchment(index) {
    this.scheduling.attatchmets.splice(index, 1);
    console.log(this.scheduling.attatchmets);
  }

  private getContryCode(internationalPhoneNumber: string) {
    let cCode;
    let finalCountryCode = "";
    let countries = this.countryCodeValue.countryService.getCountries();

    if (countries) {
      if (internationalPhoneNumber.includes("+")) {
        let _cCode = internationalPhoneNumber.trim().split("+")[1];
        if (_cCode && _cCode.includes("-")) {
          cCode = _cCode.split("-")[0];
        } else if (_cCode && _cCode.includes(" ")) {
          cCode = _cCode.split(" ")[0];
        }
        for (let i = 0; i < countries.length; i++) {
          if (cCode?.trim() === countries[i].dialCode.toString()) {
            finalCountryCode = countries[i].countryCode;
          }
        }
      }
    }

    return finalCountryCode;
  }

  changeShowNotification($event) {
    if (!this.isReceiveNotification) {
      this.isShowWhatsapp = false;
      this.isShowSMS = false;
      this.scheduling.whatsapp_phone_number = "";
      this.scheduling.sms_phone_number = "";
    } else {
      if (
        this.countryCodeValue &&
        this.countryCodeValue.selectedCountry &&
        this.countryCodeValue.selectedCountry.countryCode
      ) {
        this.smsCCodeValue = this.countryCodeValue.selectedCountry.countryCode;
        this.whatsappCCodeValue =
          this.countryCodeValue.selectedCountry.countryCode;

        this.scheduling.whatsapp_phone_number =
          this.scheduling.countryCode.replace("-", "");
        this.scheduling.sms_phone_number = this.scheduling.countryCode.replace(
          "-",
          ""
        );

        setTimeout(() => {
          this.scheduling.whatsapp_phone_number = "";
          this.scheduling.sms_phone_number = "";
        }, 200);
      }
    }
  }

  changeShowWhatsapp($event) {
    if (
      this.countryCodeValue &&
      this.countryCodeValue.selectedCountry &&
      this.countryCodeValue.selectedCountry.countryCode
    ) {
      this.whatsappCCodeValue =
        this.countryCodeValue.selectedCountry.countryCode;
    }

    if (!this.isShowWhatsapp) {
      this.scheduling.whatsapp_phone_number = "";
    } else {
      this.scheduling.whatsapp_phone_number =
        this.scheduling.countryCode.replace("-", "");
    }
  }

  changeShowSMS($event) {
    if (
      this.countryCodeValue &&
      this.countryCodeValue.selectedCountry &&
      this.countryCodeValue.selectedCountry.countryCode
    ) {
      this.smsCCodeValue = this.countryCodeValue.selectedCountry.countryCode;
    }

    if (!this.isShowSMS) {
      this.scheduling.sms_phone_number = "";
    } else {
      this.scheduling.sms_phone_number = this.scheduling.countryCode.replace(
        "-",
        ""
      );
    }
  }

  changeCountryCode() {
    this.isShowSMS = false;
    this.changeShowSMS(null);
    this.isShowWhatsapp = false;
    this.changeShowWhatsapp(null);
    this.validateCountryCode(this.scheduling.countryCode);
  }

  startTimer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timerRunning = setInterval(() => {
      // if (moment(this.currentDate).diff(this.dateWith10MinDiff) >= 0) {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.displayTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(this.timerRunning);
        this.sessionTimeoutModal.show();
      }
      // } else {
      //   console.log("timer is outside not  working!! :-(");
      //   clearInterval(timer);
      // }
    }, 1000);
  }

  goBack() {
    this.sessionTimeoutModal.hide();
    this.router.navigate(["availability"]);
    this.clearBookingByWebSocketMessage();
  }

  onPaste(event) {
    event.stopPropagation();
    event.preventDefault();
    let clipboardData = event.clipboardData || window.Clipboard;
    let pastedText = clipboardData.getData("text");
    const phoneUtil = PhoneNumberUtil.getInstance();
    this.scheduling.countryCode = pastedText;
    let cCodeValueInNumber =
      pastedText && pastedText.includes("+")
        ? phoneUtil
            .parseAndKeepRawInput(this.scheduling.countryCode)
            .getCountryCode()
        : "";
    let countries = this.countryCodeValue.countryService.getCountries();
    for (let i = 0; i < countries.length; i++) {
      if (cCodeValueInNumber.toString() === countries[i].dialCode) {
        this.cCodeValue = countries[i].countryCode;
      }
    }
    if (!cCodeValueInNumber) {
      this.cCodeValue = "";
    }
    this.validateCountryCode(this.scheduling.countryCode);
  }

  clearBookingByWebSocketMessage() {
    this.commonService.setDisableSlotWebSocketSubject(
      {
        action: 'send',
        message: { booking_id: this.commonService.bookingId }
      }
    );
  }

  ngOnDestroy() {
    if (this.timerRunning) {
      console.log("ngOnDestroy old timer is running");
      clearInterval(this.timerRunning);
    }
  }

  getScheduleByMeta() {
    this.commonService.scheduledByMetaBehavior.subscribe((data) => {
      this.scheduleByMeta = data;
    });
  }

  toMakeDisable(event) {
    if (this.readOnly) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
  }

  validateCountryCode(e) {
    if (!e) {
      this.phoneError = "";
      return;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    try {
      const phone = phoneUtil.parseAndKeepRawInput(e);
      if (
        !(phoneUtil.isValidNumber(phone) && phoneUtil.isPossibleNumber(phone))
      ) {
        this.phoneError = "Invalid phone number";
        return;
      }

      this.phoneError = "";
      this.phoneObj = {
        region: phoneUtil.getRegionCodeForNumber(phone),
        number: phone.getNationalNumber(),
        cc: phone.getCountryCode(),
      };
      this.cCodeValue = this.phoneObj.region;
    } catch (err) {
      console.log(err?.message);
    }
  }

  select(event) {
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    console.log(event.target.value.substr(start, end - start));
    return event.target.value.substr(start, end - start);
  }

  disableButton() {
    if (
      !this.scheduling.name ||
      !this.scheduling.email ||
      !this.scheduling.countryCode
    ) {
      return true;
    } else {
      return false;
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(e: Event) {
    this.clearBookingByWebSocketMessage();
    e.preventDefault();
    (e as BeforeUnloadEvent).returnValue = '';
  }
}
