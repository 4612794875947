export class Constants {
  /**
   * Created by Anil Suryavanshi on 11/05/20.
   */
  static apiEndPoints = {
    schedulerConfigurations: "scheduler_configurations",
    getAvailability: "availability",
    bookSlot: "book_slot",
    updateSlot: "update_slot",
    reinvite: "resend_invite",
    studioConfiguration: "configurations",
    availability_info: "availability_info"
  };
}
