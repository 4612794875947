<!-- Success page - NEW DESIGN -->

<div
  [ngStyle]="{
    display: (loaderService.showPageLoader | async) ? 'none' : 'block'
  }"
  class="bcl-success-page"
>
  <div class="bcl-success-container">
    <div class="bcl-success-wrp">
      <div class="bcl-success-title">
        <div class="bcl-meeting-title">Builder.ai - {{ call_label }}</div>
        <span
          *ngIf="commonService.location"
          (click)="openMeetingLink(commonService.location)"
          class="copy-meeting-link"
          >Meeting link
          <em
            [class.copied]="addClassCopy"
            ngxClipboard
            [cbContent]="commonService.location"
            (click)="$event.stopPropagation(); addClassForCopy()"
            class="copy-icon"
            ><img src="../assets/images/bcl-copy-icon.svg" alt="Copy" />
            <div class="installmentContentTooltip">
              <div class="instalment-content-inner">
                <p>Link copied</p>
              </div>
            </div>
          </em>
        </span>
      </div>
      <div class="bcl-success-body">
        <div class="bcl-body-content">
          <div class="bcl-content-title">
            <em class="bcl-icon"
              ><img
                src="../assets/images/bcl-calendar-icon.svg"
                alt="Calendar" /></em
            >When
          </div>
          <div class="bcl-inner-content">
            <div class="bcl-meeting-timings">
              <ul class="timing-row">
                <li>{{ commonService.start_time_slot }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          *ngIf="commonService.participants?.length > 0"
          class="bcl-body-content"
        >
          <div class="bcl-content-title">
            <em class="bcl-icon"
              ><img
                src="../assets/images/bcl-avatar-icon.svg"
                alt="Member" /></em
            >Invited members
          </div>
          <div class="bcl-inner-content">
            <div class="guests-list-row">
              <div
                *ngFor="let participant of commonService.participants"
                class="guest-list-col"
              >
                <div class="guest-card">
                  <span class="name-initial">{{
                    participant.name.slice(0, 1) | titlecase
                  }}</span>
                  <div class="guest-content">
                    <div class="name">{{ participant.name | titlecase }}</div>
                    <div class="email">{{ participant.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bcl-common-btn-group">
    <div class="error-warning" *ngIf="message">
      <div class="success-msg">{{ message }}</div>
    </div>
    <div class="bcl-btn-grp">
      <button
        class="bcl-common-button bcl-green-btn w-100"
        (click)="reInvite()"
      >
        Resend Invite
      </button>
    </div>
  </div>
</div>
<!-- Success page - NEW DESIGN -->
