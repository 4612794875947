<div class="bcl-schedule-header">
  <!--Header Part Calls-->
  <div class="bcl-circle-box">
    <div
      class="meeting-success-icon"
      *ngIf="router.url === '/schedule-success'"
    >
      <img src="../../../assets/images/bcl-success-icon.svg" alt="" />
      <em class="user-dp">
        <img src="{{ commonService.call_productologist_avatar_url }}" alt="" />
      </em>
    </div>
    <ul *ngIf="router.url !== '/schedule-success' && commonService.show_avatar">
      <li class="bcl-user-placeholder">
        <img
          class="bcl-main-logo"
          src="{{ commonService.call_productologist_avatar_url }}"
          alt=""
        />
      </li>
      <li class="bcl-phn-icon">
        <img
          class="bcl-main-logo"
          src="assets/images/bcl-phn-icon-black.svg"
          alt=""
        />
      </li>
    </ul>
    <div class="bcl-schedule-heading-wrp">
      <div
        class="bcl-schedule-heading bcl-common-heading"
        [ngClass]="{ 'skeleton-loader w-25 mb-2': !commonService.call_header }"
      >
        {{ commonService.call_header }}
      </div>
      <div
        *ngIf="router.url !== '/schedule-form'"
        class="bcl-schedule-content"
        [ngClass]="{ 'skeleton-loader w-75': !commonService.call_description }"
      >
        {{ commonService.call_description }}
        <p *ngIf="seeSlots" class="bottom-spacing">You can't schedule a call from here, this view is only to show available slots of the user.</p>
      </div>
      <div *ngIf="router.url === '/schedule-form'" class="bcl-schedule-time">
        <em class="cal-icon"
          ><img
            src="../../../assets/images/bcl-calendar-white.svg"
            alt="Calendar"
        /></em>
        <span class="time">{{ commonService.start_time_slot }} </span>
      </div>
    </div>
  </div>
</div>
