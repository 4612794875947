import { Injectable } from '@angular/core';
import { UrlParameterModel } from '../models/url-parameters-model/url-parameters-model';
import { ModelMapperService } from './model-mapper.service';

  /**
 * Created by Anil Suryavanshi on 12/05/20.
 */
@Injectable({providedIn:'root'})

export class HelperService{

constructor(public modelMapperService: ModelMapperService){
    
}

    public getUrlParamsFromUrl(): UrlParameterModel {
        const path = window.location.href;
        let query;
        if (path.indexOf('?') !== -1) {
          query = path.substr(path.indexOf('?') + 1);
          const result = {};
          query.split('&').forEach((part) => {
            const item = part.split('=');
            if (item[0] != '') {
              result[item[0]] = decodeURIComponent(item[1]);
            }
          });
          return this.modelMapperService.mapToUrlParameterModel(new UrlParameterModel(), result);
        }
        return null;
      }
}